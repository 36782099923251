<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}

.bg-dark {
  background-color: #e6e6e6 !important;
}

.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-right {
  justify-content: right;
}

.align-center {
  align-items: center;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span v-if="selectedData.code != undefined">
      <h4 class="mb-5">Detail Invoice</h4>
    </span>
    <span v-else>
      <h4 class="mb-5">Create Invoice</h4>
    </span>

    <div class="vx-row mb-2">
      <div class="vx-col sm:w-1/2 w-full">
        <label class="vs-input--label">Supplier</label>

        <multiselect
          class="selectExample"
          v-model="create.supplier"
          :options="option.supplier"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          :internal-search="true"
          placeholder=" Type to search"
          track-by="ID"
          label="Label"
          @select="selectedInvoice"
          :disabled="viewOnly"
        >
        </multiselect>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <label class="vs-input--label">Warehouse</label>

        <multiselect
          class="selectExample"
          v-model="create.warehouse"
          :options="option.warehouse"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          :internal-search="true"
          placeholder=" Type to search"
          track-by="ID"
          label="name"
          @select="selectedInvoice"
          :disabled="viewOnly"
        >
        </multiselect>
      </div>
      <div class="vx-col w-full mb-2">
        <label class="vs-input--label">Purchase Order</label>

        <multiselect
          class="selectExample"
          v-model="create.invoice"
          :options="option.invoice"
          :multiple="true"
          :allow-empty="true"
          :group-select="false"
          :max-height="160"
          :limit="4"
          :internal-search="true"
          placeholder=" Type to search"
          track-by="id"
          label="label"
          @select="selectedInvoice"
          :max="1"
          :disabled="viewOnly"
        >
        </multiselect>
      </div>
      <div v-if="!globalIsEmptyObject(create.invoice)" class="vx-col w-full">
        <vs-card v-for="(invoice, index) in create.invoice" :key="index">
          <div slot="header">
            <div class="vx-row" style="padding-left: 10px">
              <div style="width: 80%">
                <h6 style="left-margin: 1%">
                  {{ invoice.code }}
                </h6>
              </div>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-2/3 w-full">
              Date : {{ globalDateFormat(invoice.date) }}<br />
              Due Date : {{ globalDateFormat(invoice.eta) }}<br />
              Posting Date : {{ globalDateFormat(invoice.posting_date) }}<br />
              Created Date : {{ globalDateFormat(invoice.created_at) }}<br />
              Sales Order Code : {{ invoice.so_code }}<br />
              Invoice Reference Code : {{ invoice.external_code }}<br />
              Note : {{ invoice.notes }}
            </div>
            <div class="vx-col sm:w-1/3 w-full">
              Subtotal : {{ priceFormat(invoice.subtotal) }}<br />
              Discount : {{ priceFormat(invoice.discount) }}<br />
              Subtotal Charge : {{ priceFormat(invoice.total_charge) }}<br />
              Other Cost : N/A<br />
              Tax : {{ priceFormat(invoice.tax) }}<br />
              Total : {{ priceFormat(invoice.total) }}<br />
              Remaining : N/A
            </div>
            <!-- {{ invoice }} -->
          </div>
        </vs-card>
      </div>

      <vs-divider style="width: 100%; margin-left: 2%">Invoice Data</vs-divider>
      <div class="vx-col sm:w-1/2 w-full">
        <vs-input
          name="Invoice Number"
          v-model="create.invoiceNumber"
          class="w-full input-readonly"
          readonly
          label="Invoice Number"
        />
        <!-- <vs-input name="invoiceType" v-model="create.invoiceType" class="w-full"
					 label="Invoice Type"/> -->
        <vs-input
          name="External Code"
          v-model="create.externalCode"
          class="w-full"
          label="External Code"
          v-validate="'required'"
          :disabled="viewOnly"
        />
        <vs-input
          name="Barcode"
          v-model="create.barcode"
          class="w-full"
          v-validate="'required'"
          label="Barcode"
          :disabled="viewOnly"
        />
      </div>

      <div class="vx-col sm:w-1/2 w-full">
        <label class="vs-input--label">Invoice Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Invoice Date"
          v-model="create.invoiceDate"
          :danger="errors.has('invoiceDate')"
          :disabled="viewOnly"
        ></datepicker>
        <span
          v-show="errors.has('invoiceDate')"
          class="text-danger text-sm help is-danger"
        >
          {{ errors.first("invoiceDate") }}
        </span>
        <label class="vs-input--label">Payment Term</label>
        <multiselect
          class="selectExample"
          v-model="create.paymentTerm"
          :options="option.paymentTerm"
          @select="changeDueDate"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="id"
          label="name"
          :disabled="viewOnly"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.name }}</span>
            </div>
          </template>
        </multiselect>
        <!-- display: block -->
        <span
          v-show="errors.has('paymentTerm')"
          class="text-danger text-sm help is-danger"
        >
          {{ errors.first("paymentTerm") }}
        </span>
        <label class="vs-input--label">Invoice Due Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Invoice Due Date"
          v-model="create.invoiceDueDate"
          :danger="errors.has('invoiceDueDate')"
          :disabled-dates="dueDateDisabled"
          :disabled="viewOnly"
        ></datepicker>
        <span
          v-show="errors.has('invoiceDueDate')"
          class="text-danger text-sm help is-danger"
        >
          {{ errors.first("invoiceDueDate") }}
        </span>
      </div>

      <div class="vx-col w-full mt-6">
        <div class="vx-row mb-2">
          <div class="vx-col w-1/3">
            <vs-input
              class="w-full input-readonly"
              label="PO Item Value"
              v-validate="'required'"
              name="PO Item Value"
              readonly
              v-model="create.itemValue"
            />
            <vs-input
              class="w-full input-readonly"
              label="PO Charges Value"
              v-validate="'required'"
              readonly
              name="PO Charges Value"
              v-model="create.chargeValue"
            />
          </div>
          <div class="vx-col w-1/3">
            <vs-input
              class="w-full input-readonly"
              label="PO Tax Value"
              readonly
              name="PO Tax Value"
              v-model="create.taxValue"
            />
            <vs-input
              class="w-full input-readonly"
              label="PO Discount Value"
              readonly
              name="PO Discount Value"
              v-model="create.DiscountValue"
            />
          </div>
          <div class="vx-col w-1/3 d-flex justify-center align-center">
            <vs-input
              v-validate="'required'"
              class="w-full input-readonly"
              label="PO Total"
              readonly
              v-model="create.totalPo"
            />
          </div>
        </div>
      </div>
      <vs-divider style="width: 100%; margin-left: 2%">Tax Info</vs-divider>
      <div class="vx-col w-full mt-6">
        <div class="vx-row mb-2">
          <div class="vx-col w-1/3">
            <vs-input
              class="w-full input-readonly"
              label="Tax Address"
              name="Tax Address"
              v-model="create.taxAddress"
              readonly
            />

            <!-- <vs-input
              class="w-full"
              label="Tax Code"
              name="Tax Code"
              v-model="create.taxCode"
              :disabled="viewOnly"
            /> -->
            <label class="vs-input--label">Tax Code</label>
            <multiselect
              class="selectExample"
              v-model="create.tax"
              :options="option.tax"
              @select="changeTaxRate"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="160"
              :limit="4"
              placeholder=" Type to search"
              track-by="ID"
              label="Name"
              :disabled="viewOnly"
            >
              <template slot="singleLabel" slot-scope="dt">
                <span class="option__desc">
                  <span class="option__title">{{ dt.option.Name }} ({{ dt.option.Rate }}%)</span>
                </span>
              </template>

              <template slot="option" slot-scope="dt">
                <div class="option__desc">
                  <span class="option__title">{{ dt.option.Name }} ({{ dt.option.Rate }}%)</span>
                </div>
              </template>
            </multiselect>
          </div>
          <div class="vx-col w-1/3">
            <vs-input
              class="w-full input-readonly"
              label="Tax ID"
              readonly
              name="Tax ID"
              v-model="create.supplierNpwp"
            />
            <label class="vs-input--label">Invoice Tax Date</label>
            <datepicker
              class="w-full"
              v-validate="'required'"
              label="Tax Date"
              :inline="false"
              name="Tax date"
              v-model="create.taxDate"
              :danger="errors.has('Tax date')"
              :disabled="viewOnly"
            ></datepicker>
            <span
              v-show="errors.has('Tax date')"
              class="text-danger text-sm help is-danger"
            >
              {{ errors.first("Tax date") }}
            </span>
          </div>
        </div>
      </div>
      <vs-divider style="width: 100%; margin-left: 2%"></vs-divider>
      <div class="vx-col w-full mt-12">
        <div class="vx-row mb-2">
          <div class="vx-col w-1/3">
            <vs-input
              v-validate="'required'"
              label="DPP + Charges"
              class="w-full"
              name="DPP + Charges"
              @keypress="globalIsNumber($event)"
              @keyup="create.dpp = formatPrice(create.dpp.toString())"
              @blur="
                create.dpp = priceFormat(create.dpp ? create.dpp.toString() : 0)
              "
              v-model="create.dpp"
              :disabled="viewOnly"
            />
            <span
              v-show="errors.has('DPP + Charges')"
              class="text-danger text-sm help is-danger"
            >
              {{ errors.first("DPP + Charges") }}
            </span>
          </div>
          <div class="vx-col w-1/3 justify-right align-center">
            <vs-input
              label="Tax Value"
              class="w-full"
              name="Tax Value"
              @keypress="globalIsNumber($event)"
              @keyup="
                create.taxInvoice = formatPrice(create.taxInvoice.toString())
              "
              @blur="
                create.taxInvoice = priceFormat(
                  create.taxInvoice ? create.taxInvoice.toString() : 0
                )
              "
              v-model="create.taxInvoice"
              :disabled="viewOnly"
            />
            <span
              v-show="errors.has('Tax Value')"
              class="text-danger text-sm help is-danger"
            >
              {{ errors.first("Tax Value") }}
            </span>
          </div>
          <div class="vx-col w-1/3 justify-center align-center">
            <vs-input
              v-validate="'required|totalInvoiceValidation'"
              label="Advance Invoice Value"
              class="w-full input-readonly"
              name="Advance Invoice Value"
              readonly
              v-model="totalInvoiceValue"
            />
            <span
              v-show="errors.has('Advance Invoice Value')"
              class="text-danger text-sm help is-danger"
            >
              {{ errors.first("Advance Invoice Value") }}
            </span>
          </div>
        </div>
      </div>

      <vs-divider style="width: 100%">Notes</vs-divider>
      <template v-for="note in fileNotes">
        <b v-bind:key="note"
          >{{ note }}
          <br />
        </b>
      </template>
      <div class="vx-col w-full">
        <label class="vs-input--label">Note</label>
        <vs-textarea v-model="create.note" />
      </div>

      <vs-divider style="width: 100%; margin-left: 2%">Attachment</vs-divider>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>File</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <input
              id="fileInput"
              name="file"
              class="w-full inputx"
              type="file"
              ref="file"
              multiple="multiple"
              accept=".jpg, .jpeg, .png, .pdf, .xlsx, .xls"
            />
          </div>
        </div>
      </div>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <vs-button
              :disabled="viewOnly"
              class="mr-3 mb-2"
              @click="handleAttachment"
              >Add Attachment</vs-button
            >
          </div>
        </div>
      </div>
      <!-- display: block -->
      <vs-divider
        style="width: 100%; margin-left: 2%"
        v-if="fileAttachment.length > 0"
      >
        List Attachment
      </vs-divider>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <table class="vs-table vs-table--tbody-table">
          <template v-for="(tr, i) in fileAttachment">
            <tr
              class="tr-values vs-table--tr tr-table-state-null selected"
              v-bind:key="tr.NameFile+tr.ListID"
            >
              <td class="td vs-table--td">{{ tr.NameFile }}</td>

              <td class="td vs-table--td">
                <vx-tooltip text="Show" v-if="tr.PathFile != ''">
                  <vs-button
                    :disabled="!tr.IsSaved"
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                    @click.stop="downloadFileAwsS3(tr.PathFile)"
                  />
                </vx-tooltip>
              </td>

              <td class="td vs-table--td">
                <template>
                  <vx-tooltip text="Delete">
                    <vs-button
                      :disabled="viewOnly"
                      type="line"
                      icon-pack="feather"
                      icon="icon-trash"
                      @click.stop="
                        () => {
                          fileAttachment.splice(i, 1);
                        }
                      "
                    />
                  </vx-tooltip>
                </template>
              </td>
            </tr>
          </template>
        </table>
      </div>
    </div>

    <div class="vx-row mb-2">
			<div class="vx-col w-full">
				<span>Posting Date</span>
			</div>
			<div class="vx-col w-full">
				<datepicker class="w-full" v-validate="'required'" :inline="false" name="PostingDate" pickTime="false"
					v-model="posting_date" placeholder="Select Posting Date"></datepicker>
			</div>
		</div>

    <div class="vx-row mb-2" style="
        border-bottom: 1px solid #c1bbff;
        padding-top: 10px;
        max-width: 50%;
        margin: auto;
      "></div>
		<div style="margin-top: 6%">
			<!-- <vs-button v-if="used_dn_value == 0" @click="handleReversal(dn_id)" icon-pack="feather"  icon="icon-corner-up-left" color="red">
        Reverse
      </vs-button> -->
		</div>
		<div class="vx-row mb-2">
			<h5 style="margin: auto">Apply Invoice</h5>
		</div>
		<div class="vx-row mb-2">
			Advance Invoice Value : {{ priceFormat(parseFloat(this.grand_total.toString().replace(/,/g, ''))) }}<br>
      Advance Invoice Paid Value : {{ priceFormat(parseFloat(this.selectedData.paid_value.toString().replace(/,/g, ''))) }}<br>
			Invoice Selected : {{ priceFormat(invoice_value_selected) }}<br>
			Remaining Value : {{ priceFormat(remaining_value) }}
		</div>
		<vs-table stripe border description :sst="true" :data="table.data_invoice_open">
			<template slot="thead">
				<vs-th></vs-th>
				<vs-th>Invoice Code</vs-th>
				<vs-th>Invoice Date</vs-th>
				<vs-th>Paid Value</vs-th>
				<vs-th>Unpaid Value</vs-th>
				<vs-th>Paid Now</vs-th>
			</template>

			<template slot-scope="{ data }">
				<vs-tr :data="tr" :key="indextr+'invoiceunpaid'" v-for="(tr, indextr) in data" :class="data[indextr].class">
					<vs-td>
						<vs-checkbox v-on:click="check(tr.PurchaseInvoiceID, indextr)" :checked="checked.includes(tr.PurchaseInvoiceID)">
						</vs-checkbox>
					</vs-td>
					<vs-td :data="data[indextr].code">
						<div class="vx-row ml-0">
							<span class="w-5/5">{{ tr.InvoiceCode }}</span>
						</div>
					</vs-td>
					<vs-td :data="data[indextr].code">
						<div class="vx-row ml-0">
							<span class="w-5/5">{{ globalDateFormat(tr.InvoiceDate) }}</span>
						</div>
					</vs-td>
					<vs-td :data="data[indextr].code">
						<div class="vx-row ml-0">
							<span class="w-5/5">{{ formatPrice(parseFloat(tr.PaidValue)) }}</span>
						</div>
					</vs-td>
					<vs-td :data="data[indextr].code">
						<div class="vx-row ml-0">
							<span class="w-5/5">{{ formatPrice(parseFloat(tr.UnpaidValue)) }}</span>
							<!-- <span class="w-5/5" v-if="tr.CurrencyCode!= 'IDR'">&nbsp;({{ tr.CurrencyCode }})</span> -->
						</div>
					</vs-td>
					<vs-td style="text-align: right">
						<template v-if="checked[checked.length - 1] == tr.PurchaseInvoiceID">
							<vs-input class="w-full" @keypress="isNumber($event)"
								v-model="dataChecked[checked.length - 1].paid_now"
								@keyup="handlePrice(checked.length - 1)" />
						</template>
						<template v-else-if="checked.includes(tr.PurchaseInvoiceID)">
							{{ formatPrice(tr.paid_now) }}
						</template>
						<template v-else> 0 </template>
					</vs-td>
				</vs-tr>
			</template>
		</vs-table>

		<div class="vx-col w-full">
			<br />
			<!-- display: block -->
			<vs-button class="mb-2" v-on:click="handleSubmit">
				Apply
			</vs-button>
		</div>

    <!-- <div class="vx-col w-full">
      <br />
      <vs-button v-if="!viewOnly" class="mb-2" v-on:click="handleSubmit">
        {{ selectedData ? "Save" : "Create" }}
      </vs-button>
    </div> -->
  </div>
</template>

<script>
import qs from "qs";
import Datepicker from "vuejs-datepicker";
import moment, { max } from "moment";
// import { Validator } from 'vee-validate';

export default {
  props: {
    selectedData: null,
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Datepicker,
  },
  created() {
    this.$validator.extend("totalInvoiceValidation", {
      validate: (value) => {
        const valFloat = parseFloat(value.toString().replace(/,/g, ""));
        const poTotal = parseFloat(
          this.create.totalPo.toString().replace(/,/g, "")
        );
        if (valFloat <= poTotal && poTotal > 0) {
          return true;
        }
        return false;
      },
      getMessage: () =>
        "Total advance invoice value must be greater than 0 and lower than PO total",
    });
  },
  data() {
    return this.initialState();
  },
  methods: {
    selectedInvoice(arg) {
      let remaining = parseFloat(arg.Remaining).toFixed(2);
      this.maxAdjustment = remaining;

      this.isDropdownChange = true;
      this.$nextTick(() => (this.isDropdownChange = false));
      console.log(this.isDropdownChange, "dari sini mungkin");
    },
    initialState() {
      return {
        posting_date: new Date().toISOString().slice(0, 10),
        fileAttachment: [],
        checked: [],
        table: {
					data: [],
					data_invoice_open: [],
				},
				dataChecked: [],
        search: "",
        isDropdownChange: false,
        maxAdjustment: 0,
        grand_total: 0,
        render: 0,
        fileNotes: [],
        isQuery: false,
        option: {
          invoice: [],
          supplier: [],
          warehouse: [],
          coa: [],
          costCenter: [],
          paymentTerm: [],
          tax: [],
        },
        additionalCoa: [
          {
            list_id: null,
            additional_value: null,
            cost_center_id: null,
            cost_center_code: null,
            cost_center_name: null,
            coa_id: null,
            coa_code: null,
            coa_name: null,
            reason: null,
          },
        ],
        inputs: [
          {
            additional_value: null,
            cost_center: null,
            cost_center_required: false,
            coa: null,
            reason: null,
          },
        ],
        create: {
          invoice: [],
          supplier: {},
          warehouse: {},
          adjustmentValue: null,
          postingDate: null,
          invoiceDate: new Date(),
          invoiceDueDate: null,
          coa: null,
          reason: "",
          costCenter: null,
          controlValue: null,
          paymentTerm: {},
          itemValue: 0,
          totalValue: 0,
          totalPo: 0,
          DiscountValue: 0,
          taxValue: 0,
          chargeValue: 0,
          dpp: 0,
          taxInvoice: 0,
          supplierNpwp: "",
          taxDate: new Date(),
          taxCode: "",
          taxAddress: "",
          tax: {},
        },
        customNotif: {
          custom: {
            adjustmentValue: {
              required: "Adjustment value is empty",
              between: (field, max) =>
                `Adjustment value field must be between 0 and ${
                  max.toString().split(",")[1]
                }`,
            },
            reason: {
              required: () => "Reason is empty",
            },
            costCenter: {
              required: "Cost center value is empty",
            },
            postingDate: {
              required: "Posting date value is empty",
            },
          },
        },
        dueDateDisabled: {
          to: moment().subtract(1, "days").toDate(),
        },
      };
    },
    mappingAttachment() {
      console.log("attachment", this.selectedData);
      if (!this.globalIsEmptyObject(this.selectedData.attachment)) {
        this.selectedData.attachment.map((v) => {
          this.fileAttachment.push({
            ListID: v.ListID,
            NameFile: v.NameFile,
            PathFile: v.PathFile,
            IsSaved: true,
          });
        });
      }
    },
    check(val, index) {
			if (this.checked.includes(val)) {
				this.checked.splice(this.checked.indexOf(val), 1);
				this.dataChecked = this.dataChecked.filter((v) => {
					return v.PurchaseInvoiceID != val;
				});
			} else {
				this.checked.push(val);
				this.dataChecked.push(this.table.data_invoice_open[index]);
			}
		},
    handlePrice(index) {
			let paid = this.dataChecked[index].paid_now.toString().replace(/,/g, '')
			console.log(this.dataChecked[index], paid)
			if (parseFloat(this.dataChecked[index].UnpaidIDR) < parseFloat(paid)) {
				paid = this.dataChecked[index].UnpaidIDR
			}
			this.dataChecked[index].paid_now = this.formatPrice(paid)
			// return this.formatPrice(paid_now)
			// dataChecked[checked.length - 1].paid_now = formatPrice(
			//                 dataChecked[checked.length - 1].paid_now.toString() == ''? '0': (tr.unpaid_value < dataChecked[checked.length - 1].paid_now ? tr.unpaid_value.toString():dataChecked[checked.length - 1].paid_now.toString() )
			//               )
		},
    changeDueDate(...args) {
      if (this.create.paymentTerm) {
        console.log(this.create.invoiceDate)
        console.log(args)
        this.create.invoiceDueDate = moment(this.create.invoiceDate)
          .add(args[0].due_invoice_period, "days")
          .toDate();
      }
    },
    changeTaxRate(...args) {
      if (this.create.tax) {
        console.log(this.create.tax)
        console.log(args)

        this.create.taxInvoice = this.priceFormat(parseFloat(this.create.dpp.toString().replace(/,/g, "")) * this.create.tax.Rate/100)
      }
    },
    handleAttachment() {
      if (this.$refs.file.files.length > 0) {
        this.file = this.$refs.file.files;
        // this.fileAttachment = [];
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          console.log(this.file[i]);
          // this.uploadData(i);
          this.fileAttachment.push({
            NameFile: this.file[i].name,
            File: this.file[i],
            ListID: 0,
            IsSaved: false,
          });
        }
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    formatPrice(angka, prefix = "") {
      return angka
        .toString()
        .replace(/,/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async getInv() {
      try {
			this.$vs.loading();
			console.log(this.supplier_id, "cari supplier id")
			this.$http
				.get("/api/v1/advance-invoice/get-invoice-unpaid", {
					params: {
						territory_id: this.selectedData.territory_id,
						territory_area_id: this.selectedData.territory_area_id,
            purchase_order: this.selectedData.AdvanceInvoiceLine[0].document_code
						// dn_type: this.dn_type,
						// supplier_id: this.supplier_id,
          },
				})
				.then((resp) => {
					this.$vs.loading.close();
					if (resp.code == 200) {
						// this.table.data_invoice_open = resp.data
						// let filteredData = resp.data.filter(x => x.CurrencyCode == "IDR")
						// console.log(filteredData, "filteredData");
						// filteredData.map(row => {
						// 	let paidNow = parseFloat(row.UnpaidValue)//.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
						// 	let unpaidIDR = parseFloat(row.UnpaidValue)
						// 	if (row.CurrencyCode != 'IDR') {
						// 		paidNow = paidNow * row.ExchageRates.ExchangeRate
						// 		unpaidIDR = paidNow
						// 	}
						// 	this.table.data_invoice_open.push({ ...row, ...{ bi_rate_id:row.ExchageRates.ID,exchange_rate:row.ExchageRates.ExchangeRate, UnpaidIDR: unpaidIDR,paid_now: parseFloat(paidNow).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') } })
						// })
						 resp.data.map(row => {
              let paidNow = parseFloat(row.UnpaidValue)//.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
						  this.table.data_invoice_open.push({...row, ...{paid_now: parseFloat(paidNow).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}})  
						})
						console.log("resp.data", resp.data)
						console.log("this.table.data_invoice_open", this.table.data_invoice_open)
						// let _this = this
						// this.table.data_invoice_open.forEach(function (element, i) {
						//   _this.table.data_invoice_open[i].paid_now = element.unpaid_value
						// });
						console.log(resp.data.records)
					} else {
						this.$vs.notify({
							color: "danger",
							title: "Error",
							text: resp.message,
							position: "top-right",
							iconPack: "feather",
							icon: "icon-x-circle",
						});
					}
				})
				.catch((error) => {
					this.$vs.loading.close();
					console.log(error);
				});
      }  catch (e) {
        this.$vs.loading.close();
        console.error(e);
      }
		},
    async getOptionSupplier() {
      //   this.$vs.loading();
      //   this.$http
      //     .get("/api/v1/master/suppliers", {
      //       params: {
      //         order: "name",
      //         sort: "asc",
      //       },
      //     })
      //     .then((resp) => {
      //       if (resp.code == 200) {
      //         this.option.supplier = resp.data.records;
      //       } else {
      //         this.$vs.notify({
      //           title: "Error",
      //           text: "Failed to get Supplier option",
      //           color: "danger",
      //           position: "top-right",
      //           iconPack: "feather",
      //           icon: "icon-check",
      //         });
      //       }
      //       this.$vs.loading.close();
      //     });
      try {
        this.$vs.loading();
        const resp = await this.$http.get("/api/v1/master/suppliers", {
          params: {
            order: "name",
            sort: "asc",
          },
        });
        if (resp.code == 200) {
          this.option.supplier = resp.data.records;
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Supplier option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        console.error(e);
      }
    },
    async getOptionWarehouse() {
      //   this.$vs.loading();
      //   this.$http
      //     .get("/api/v1/master/warehouses", {
      //       params: {
      //         order: "name",
      //         sort: "asc",
      //       },
      //     })
      //     .then((resp) => {
      //       if (resp.code == 200) {
      //         this.option.warehouse = resp.data.records;
      //       } else {
      //         this.$vs.notify({
      //           title: "Error",
      //           text: "Failed to get Warehouse option",
      //           color: "danger",
      //           position: "top-right",
      //           iconPack: "feather",
      //           icon: "icon-check",
      //         });
      //       }
      //       this.$vs.loading.close();
      //     });
      try {
        this.$vs.loading();
        const resp = await this.$http.get("/api/v1/master/warehouses", {
          params: {
            order: "name",
            sort: "asc",
          },
        });
        if (resp.code == 200) {
          this.option.warehouse = resp.data.records;
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Warehouse option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        console.error(e);
      }
    },
    async getOptionTax() {
      try {
        this.$vs.loading();

        this.$http.get("/api/v1/master/tax", {
          params: {
            order: "is_default",
            sort: "desc, name asc",
          }
        }).then(resp => {
            if (resp.code == 200) {
              this.option.tax = resp.data.records;
              if (this.globalIsEmptyObject(this.selectedData)) {
                this.create.tax = resp.data.records[0];
              }
            } else {
                this.$vs.notify({
                    title: "Error",
                    text: "Failed to get Tax option",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
            }
            this.$vs.loading.close();
        });
      } catch (e) {
        this.$vs.loading.close();
        console.error(e);
      }
    },
    async getOptionPurchaseOrder() {
      //   this.$vs.loading();
      //   this.$http
      //     .get("/api/v1/pi/list-po", {
      //       params: {
      //         supplier_id: this.create.supplier.ID,
      //         warehouse_id: this.create.warehouse.ID,
      //       },
      //     })
      //     .then((resp) => {
      //       if (resp.code == 200) {
      //         this.option.invoice = resp.data.records;
      //         console.log(resp.data.records);
      //       } else {
      //         this.$vs.notify({
      //           title: "Error",
      //           text: "Failed to get PO option",
      //           color: "danger",
      //           position: "top-right",
      //           iconPack: "feather",
      //           icon: "icon-check",
      //         });
      //         this.warehouse = null;
      //       }
      //       this.$vs.loading.close();
      //     });
      try {
        this.$vs.loading();
        const resp = await this.$http.get("/api/v1/pi/list-po", {
          params: {
            supplier_id: this.create.supplier.ID,
            warehouse_id: this.create.warehouse.ID,
          },
        });
        if (resp.code == 200) {
          this.option.invoice = resp.data.records;
          console.log(resp.data.records);
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get PO option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          this.warehouse = null;
        }
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        console.error(e);
      }
    },
    paramUpload(array) {
      var form = new FormData();
      form.append("id", this.selectedData.ID);
      form.append("file", this.file[array]);
      return form;
    },
    uploadAttachmentAfter(i, id) {
      console.log("this line jalan");
      if (this.fileAttachment.length == 0) {
        this.$emit("closeDetail", true);
        this.$vs.loading.close();
        return;
      }
      if (this.fileAttachment.length < i) {
        this.$emit("closeDetail", true);
        this.$vs.loading.close();
        return;
      }
      var form = new FormData();
      form.append("id", id);
      form.append("file", this.fileAttachment[i].File);
      this.$http
        .post("/api/v1/advance-invoice/upload-file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.uploadAttachmentAfter(++i, id);
          // this.$vs.loading.close();
        })
        .catch((error) => {
          this.uploadAttachmentAfter(++i, id);
        });
      // })
    },
    handleShow(file) {
      let objectURL;
      console.log(file);
      objectURL = URL.createObjectURL(file.File);

      // link.download = file.name; // this name is used when the user downloads the file
      var href = objectURL;
      window.open(href, "_blank").focus();
    },
    handleDeleteAttachment(id) {
      console.log(id);
      this.$vs.loading();
      var form = new FormData();
      form.append("list_id", id);
      form.append("id", this.selectedData.ID);
      this.$http
        .post("/api/v1/advance-invoice/delete-file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.fileAttachment = this.fileAttachment.filter((v) => {
              return v.ListID != id;
            });
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    async getOptionCostCenter() {
      // this.$vs.loading();
      // this.$http
      // 	.get("/api/v1/master/cost-center", {
      // 		params: {
      // 			order: "name",
      // 			sort: "asc",
      // 		},
      // 	})
      // 	.then((resp) => {
      // 		if (resp.code == 200) {
      // 			this.option.costCenter = resp.data.records;
      // 		} else {
      // 			this.$vs.notify({
      // 				title: "Error",
      // 				text: "Failed to get Cost Center option",
      // 				color: "danger",
      // 				position: "top-right",
      // 				iconPack: "feather",
      // 				icon: "icon-check",
      // 			});
      // 		}
      // 		this.$vs.loading.close();
      // 	});
      try {
        this.$vs.loading();
        const resp = await this.$http.get("/api/v1/master/cost-center", {
          params: {
            order: "name",
            sort: "asc",
          },
        });
        if (resp.code == 200) {
          this.option.costCenter = resp.data.records;
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Cost Center option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        console.error(e);
      }
    },
    getOptionCoa(query) {
      if (query.length <= 2) {
        return;
      }
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/chart-of-account", {
          params: {
            search: query,
            order: "code",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            // var _this = this
            this.option.coa = [];
            let coa = [];
            resp.data.records.map((v, i) => {
              coa.push(v.coa);
            });
            this.option.coa = coa;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Coa option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    async getGeneralForm() {
      // this.getOptionCoa();
      await this.getInv();
      await this.getOptionCostCenter();
      await this.getOptionSupplier();
      await this.getOptionWarehouse();
      await this.getOptionTax();
      await this.getOptionPaymentTerm();

      if (!this.globalIsEmptyObject(this.selectedData)) {
        this.isQuery = true;
        this.create.supplier = this.option.supplier.find(
          (v) => v.ID == this.selectedData.partner_id
        );
        this.create.warehouse = this.option.warehouse.find(
          (v) => v.ID == this.selectedData.warehouse_id
        );
        this.create.paymentTerm = this.option.paymentTerm.find(
          (v) => v.id == this.selectedData.payment_term_id
        );
        this.create.tax = this.option.tax.find(
          (v) => v.ID == this.selectedData.tax_id
        );

        await this.getOptionPurchaseOrder();

        const poIDs = this.selectedData.AdvanceInvoiceLine.map((v) => {
          return v.document_id;
        });

        this.isDropdownChange = true;
        this.create.invoice = this.option.invoice.filter((v) => {
          return poIDs.includes(v.id);
        });
        // this.create.invoice = this.option.invoice.filter((v) => {
        //   return this.selectedData.invoices.find((x) => x.id == v.id);
        // });
        this.create.invoiceNumber = this.selectedData.code;
        this.create.externalCode = this.selectedData.external_code;
        this.create.barcode = this.selectedData.barcode;
        this.create.invoiceDate = this.selectedData.date;
        this.create.invoiceDueDate = this.selectedData.due_date;
        this.create.taxAddress = this.selectedData.tax_address;
        this.create.taxCode = this.selectedData.tax_code;
        this.create.taxDate = moment.utc(this.selectedData.tax_date).toDate();

        // this.create.note = this.selectedData.notes;
        this.fileNotes = this.selectedData.notes.split(";");
        this.create.totalPo = this.selectedData.total_po;
        this.create.itemValue = this.selectedData.item_value;
        this.create.chargeValue = this.selectedData.charge_value;
        this.create.taxValue = this.selectedData.tax_value;
        this.create.DiscountValue = this.selectedData.discount_value;
        this.create.totalValue = this.selectedData.total_value;

        console.log("tax value dan dpp disini 4");
        if (!this.globalIsEmptyObject(this.selectedData.AdvanceInvoiceLine)) {
          console.log("tax value dan dpp disini");
          this.create.taxInvoice = this.priceFormat(this.selectedData.AdvanceInvoiceLine[0].tax_value.toString())
          this.create.dpp = this.priceFormat(this.selectedData.AdvanceInvoiceLine[0].dpp.toString())
        }

        this.isQuery = false;
        console.log(this.isDropdownChange, "dari sini mungkin 2");
      }
    },
    async getOptionPaymentTerm() {
      //   this.$vs.loading();
      //   this.$http
      //     .get("/api/v1/master/payment-term", {
      //       params: {
      //         order: "name",
      //         sort: "asc",
      //       },
      //     })
      //     .then((resp) => {
      //       if (resp.code == 200) {
      //         this.option.paymentTerm = resp.data.records;
      //       } else {
      //         this.$vs.notify({
      //           title: "Error",
      //           text: "Failed to get Payment Term option",
      //           color: "danger",
      //           position: "top-right",
      //           iconPack: "feather",
      //           icon: "icon-check",
      //         });
      //       }
      //       this.$vs.loading.close();
      //     });
      try {
        this.$vs.loading();
        const resp = await this.$http.get("/api/v1/master/payment-term", {
          params: {
            order: "name",
            sort: "asc",
          },
        });
        if (resp.code == 200) {
          this.option.paymentTerm = resp.data.records;
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Payment Term option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        console.error(e);
      }
    },
    paramData() {
			return {
				advance_invoice_id: this.selectedData.ID,
				posting_date: this.posting_date,
				description: this.create.note,
				notes: "-",
				type: 2,
				invoice: this.dataChecked.map(d => ({ ...d, paid_now: d.paid_now.replace(",", "") }))
			};
		},
    postData() {
			console.log(this.paramData())
			this.$vs.loading();
			this.$http
				.post("/api/v1/advance-invoice/apply-to-invoice", this.paramData())
				.then((resp) => {
					this.$vs.loading.close();
					if (resp.code == 200) {
						this.handleClose();
						this.$vs.notify({
							color: "success",
							title: "Success",
							text: resp.message,
							position: "top-right",
							iconPack: "feather",
							icon: "icon-x-circle",
						});
					} else {
						this.$vs.notify({
							color: "danger",
							title: "Error",
							text: resp.message,
							position: "top-right",
							iconPack: "feather",
							icon: "icon-x-circle",
						});
					}
				})
				.catch((error) => {
					this.$vs.loading.close();
					console.log(error);
				});
		},
    handleSubmit() {
      if (this.checked.length == 0) {
				this.$vs.notify({
					color: "danger",
					title: "Error",
					text: "Please select invoice",
					position: "top-right",
					iconPack: "feather",
					icon: "icon-x-circle",
				});
			} else if (this.remaining_value < 0) {
				this.$vs.notify({
					color: "danger",
					title: "Error",
					text: "Remaining value must be 0!",
					position: "top-right",
					iconPack: "feather",
					icon: "icon-x-circle",
				});
			} else if (parseFloat(this.grand_total.toString().replace(/,/g, '')) < parseFloat(this.invoice_value_selected.toString().replace(/,/g, ''))) {
        this.$vs.notify({
					color: "danger",
					title: "Error",
					text: "Selected invoice amount cannot exceed the advance invoice amount",
					position: "top-right",
					iconPack: "feather",
					icon: "icon-x-circle",
				});
      } else {
				this.dataChecked.map(v => {
					v.paid_now = v.paid_now.replace(/ /g, '')

				})
				this.postData()
				// console.log(this.paramData())
			}
    },
    handleClose() {
      this.$emit("closeDetail", true);
    },
    onChangeCoa(index) {
      if (this.inputs[index].coa.Code.slice(0, 1) == "7") {
        // console.log(this.option.costCenter);

        let costCenter = this.option.costCenter.filter((v) => v.Code == "OTH");
        // console.log(costCenter, "OTH");

        console.log(costCenter, "costCenter[0]");
        // this.create.costCenter = costCenter[0]
        this.inputs[index].cost_center = costCenter[0];
        this.inputs[index].cost_center_required = true;
      } else {
        this.inputs[index].cost_center_required = false;
      }
    },
    adjustmentValue() {
      let adjustment = 0;
      for (let i = 0; i < this.inputs.length; i++) {
        adjustment +=
          this.inputs[i].additional_value == null
            ? 0
            : parseFloat(
                this.inputs[i].additional_value.toString().replace(/,/g, "")
              );
        // console.log("adjustment", adjustment);
      }

      // console.log("adjustment2", adjustment);
      this.create.adjustmentValue = isNaN(adjustment)
        ? null
        : this.formatPrice(adjustment.toFixed(2));
    },
    removeField(index) {
      this.inputs.splice(index, 1);
    },
    addField() {
      this.inputs.push({
        additional_value: null,
        cost_center: null,
        coa: null,
        reason: null,
      });
      this.option.coa = [];
      console.log(this.option.coa);
      this.additionalCoa.push({
        list_id: null,
        additional_value: null,
        cost_center_id: null,
        cost_center_code: null,
        cost_center_name: null,
        coa_id: null,
        coa_code: null,
        coa_name: null,
        reason: null,
      });
    },
  },
  async mounted() {
    this.mappingAttachment();
    await this.getGeneralForm();
  },
  computed: {
    totalInvoiceValue() {
      if (!this.create) {
        return this.priceFormat(0);
      }

      return this.priceFormat(
        parseFloat(this.create.dpp.toString().replace(/,/g, "")) +
          parseFloat(this.create.taxInvoice.toString().replace(/,/g, "")) -
          parseFloat(this.create.DiscountValue.toString().replace(/,/g, ""))
      );
    },    
    invoice_value_selected() {
      console.log("compute");
      if (this.dataChecked.length > 0) {
        return this.dataChecked.reduce((acc, value) => {
          let paidNow = value.paid_now ? value.paid_now.toString().replace(/,/g, '') : '0';
          return acc + parseFloat(paidNow || '0');
        }, 0);
      } 
      return 0;
    },

    remaining_value() {
      let grandTotal = parseFloat(this.grand_total.toString().replace(/,/g, '')) || 0;
      let paidVal = parseFloat(this.selectedData.paid_value.toString().replace(/,/g, '')) || 0;
      // console.log(this.grandTotal);
      return grandTotal - paidVal - (this.invoice_value_selected || 0);
    },
  },
  watch: {
    selectedData: {
      async handler() {
        // console.log("selectedData changed:", oldValue, "→", newValue);
        this.mappingAttachment();
        await this.getGeneralForm();
      },
      deep: true,
    },
    "create.dpp": function () {
      this.create.taxInvoice = this.priceFormat(parseFloat(this.create.dpp.toString().replace(/,/g, "")) * this.create.tax.Rate/100)
    },
    // "option.paymentTerm": function () {
    // 	if (
    // 		this.option.paymentTerm.length > 0 &&
    // 		this.create.paymentTerm != null
    // 	) {
    // 		let _this = this;
    // 		var result = this.option.paymentTerm.filter(function (o) {
    // 			return o.id == _this.create.paymentTerm.id;
    // 		});
    // 		// this.table.payment_term.due_invoice_period =
    // 		// result[0].due_invoice_period;
    // 	}
    // },
    "create.invoice": function () {
      console.log("dari sini", this.isDropdownChange);
      if (this.create.invoice.length > 0 && this.isDropdownChange) {
        this.create.itemValue = 0;
        this.create.totalValue = 0;
        this.create.DiscountValue = 0;
        this.create.taxValue = 0;
        this.create.chargeValue = 0;
  
        this.create.invoice.forEach((e) => {
          this.create.itemValue = this.priceFormat(
            parseFloat(this.create.itemValue.toString().replace(/,/g, "")) +
              parseFloat(e.subtotal)
          );
          this.create.totalPo = this.priceFormat(
            parseFloat(this.create.totalValue.toString().replace(/,/g, "")) +
              parseFloat(e.total)
          );
          this.create.DiscountValue = this.priceFormat(
            parseFloat(this.create.DiscountValue.toString().replace(/,/g, "")) +
              parseFloat(e.discount)
          );
          this.create.taxValue = this.priceFormat(
            parseFloat(this.create.taxValue.toString().replace(/,/g, "")) +
              parseFloat(e.tax)
          );
          this.create.chargeValue = this.priceFormat(
            parseFloat(this.create.chargeValue.toString().replace(/,/g, "")) +
              parseFloat(e.total_charge)
          );
          this.create.taxAddress = e.supplier_address;
          this.create.supplierNpwp = this.create.supplier.NpwpNew;
          if (!this.globalIsEmptyObject(this.selectedData)) {
            if (this.render > 0) {
              this.create.taxInvoice = this.priceFormat(parseFloat(this.create.taxValue.toString().replace(/,/g, "")));
            }
          } else {
            this.create.taxInvoice = this.priceFormat(parseFloat(this.create.taxValue.toString().replace(/,/g, "")));
          }
          // this.create.taxInvoice = this.priceFormat(parseFloat(this.create.taxValue.toString().replace(/,/g, "")));
        });
  
        this.grand_total = this.create.totalPo;
        // this.create.dpp = this.priceFormat(
        //     parseFloat(this.create.itemValue.toString().replace(/,/g, "")) +
        //     parseFloat(this.create.chargeValue.toString().replace(/,/g, ""))
        //   );
        if (!this.globalIsEmptyObject(this.selectedData)) {
          if (this.render > 0) {
            this.create.dpp = this.priceFormat(
            parseFloat(this.create.itemValue.toString().replace(/,/g, "")) +
            parseFloat(this.create.chargeValue.toString().replace(/,/g, "")));
          }
        } else {
          this.create.dpp = this.priceFormat(
            parseFloat(this.create.itemValue.toString().replace(/,/g, "")) +
            parseFloat(this.create.chargeValue.toString().replace(/,/g, "")));
        }
        this.render++
        this.isDropdownChange = false;
      }
    },
    "create.supplier": function () {
      if (this.isQuery) {
        return;
      }

      if (this.create.supplier.ID) {
        this.getOptionPurchaseOrder();
      }
    },
    "create.warehouse": function () {
      if (this.isQuery) {
        return;
      }
      // console.log(this.create.supplier.ID, "ini apa juga");
      if (this.create.warehouse.ID) {
        this.getOptionPurchaseOrder();
        // this.getOptionAccountBank(this.supplier.ID, 0);
      }
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;

  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}

.border-danger {
  border: 1px solid rgba(var(--vs-danger), 10) !important;

  border-radius: 5px;
}
</style>
